// app.js

import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';

import { Alignment } from '@ckeditor/ckeditor5-alignment';  // Importing the package.
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { List } from '@ckeditor/ckeditor5-list';
import { Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Indent } from '@ckeditor/ckeditor5-indent';
import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';
import {
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar, 
    ImageInsertViaUrl, ImageInline, ImageBlock, AutoImage, ImageUpload,
    ImageResize, ImageResizeEditing, ImageResizeHandles, ImageResizeButtons
} from '@ckeditor/ckeditor5-image';


class Editor extends ClassicEditor {
    static builtinPlugins = [ 
        Alignment,
        BlockQuote,
        Heading,
        Link,
        List,
        Bold,
        Italic,
        Indent,
        Essentials,
        Paragraph,
        LinkImage,
        SimpleUploadAdapter,
        ImageInsertViaUrl, AutoImage, ImageUpload,
        Image, ImageCaption, ImageStyle, ImageToolbar, ImageInline, ImageBlock,
        ImageResize, ImageResizeEditing, ImageResizeHandles, ImageResizeButtons
    ];

    static defaultConfig = {
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'bold', 'italic',
                '|', 'link', 'insertImage',
                '|', 'blockQuote',
                '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
            ],
        },
        language: 'ko',
        link: {
            // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
            addTargetToExternalLinks: true,
        },
        image: {
            insert: {
                integrations: ['upload', 'url'],
                type: 'auto'
            },
            resizeUnit: "%",
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    value: null
                },
                {
                    name: 'resizeImage:50',
                    value: '50',
                },
                {
                    name: 'resizeImage:75',
                    value: '75',
                },
                {
                    name: 'resizeImage:custom',
                    value: 'custom'
                },
            ],
            toolbar: [
                'toggleImageCaption',
                'linkImage',
                'resizeImage',
            ]
        },
        table: {
            contentToolbar: [
            ]
        },
        simpleUpload: {
			      withCredentials: true
        }
    };
}

export default Editor;
